import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    entities: [],
    singleRequested: false,
    allEntities: [],
    error: null
}
const strategies = {
    FETCH_ENTITIES_ERROR: fetchEntitiesErrorTransformer,
    FETCH_ENTITIES_PENDING: fetchEntitiesPendingTransformer,
    FETCH_ENTITIES_REMOVE_PENDING: fetchEntitiesRemovePendingTransformer,
    FETCH_ENTITIES_SUCCESS: fetchEntitiesSuccessTransformer,
    FETCH_ALL_ENTITIES_SUCCESS:  fetchAllEntitiesSuccessTransformer,
    FETCH_ENTITY_SUCCESS: fetchEntitySuccessTransformer,
    FETCH_ADD_ENTITY_SUCCESS: fetchAddEntitySuccessTransformer,
    FETCH_REMOVE_ENTITY_SUCCESS: fetchRemoveEntitySuccessTransformer,
    UPDATE_ENTITY_SUCCESS: updateEntitySuccessTransformer,
    DELETE_ENTITY_SUCCESS: deleteEntitySuccessTransformer,
    SAVE_ENTITY_SUCCESS: saveEntitySuccessTransformer
};
const entitiesList = createReducer(initialState, strategies);

function fetchEntitiesErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function fetchEntitiesPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchEntitiesRemovePendingTransformer(state) {
    return { ...state, pending: false };
}
function fetchEntitiesSuccessTransformer(state, action) {
    return { ...state, pending: false, entities: action.entities.data };
}
function fetchAllEntitiesSuccessTransformer(state, action) {
    return { ...state, pending: false, allEntities: action.entities.data, singleRequested:false };
}
function fetchEntitySuccessTransformer(state, action) { 
    let entityString = state.allEntities;
    let remaintingEntities = entityString.filter(t=>t._id !== action.entity.data._id);
    remaintingEntities.push(action.entity.data);
    return { ...state, pending: false, allEntities: remaintingEntities, singleRequested:true};
}
function fetchAddEntitySuccessTransformer(state, action) {
    const remaintingEntities = state.entities.filter(t=>t._id !== action.entityId);
    return { ...state, pending: false, entities: remaintingEntities};
}
function fetchRemoveEntitySuccessTransformer(state, action) { 
    let currentEntities = state.entities;
    currentEntities.push(action.entity);
    return { ...state, pending: false, entities: currentEntities};
}
function updateEntitySuccessTransformer(state, action) { 
    let entityString = state.allEntities.filter(t=>t._id !== action.id);
    const newEntity = {
        ...action.entity,
        _id: action.id
    }
    entityString.push(newEntity);
    let adminEntityString = state.entities;
    let updatedAdminEntityString = adminEntityString;
    let entityOnAdmin = adminEntityString.filter(t=>t._id === action.id);
    if(entityOnAdmin === null) {
        updatedAdminEntityString = adminEntityString.filter(t=>t._id !== action.id);
        updatedAdminEntityString.push(newEntity)
    }
    return { ...state, pending: false, allEntities: entityString, entities: updatedAdminEntityString};
}
function deleteEntitySuccessTransformer(state, action) { 
    const currentEntities = state.allEntities;
    const newEntities = currentEntities.filter(t=>t._id !== action.id);
    return { ...state, pending: false, allEntities: newEntities};
}
function saveEntitySuccessTransformer(state, action) { 
    let currentEntities = state.allEntities;
    currentEntities.push(action.entity);
    return { ...state, pending: false, allEntities: currentEntities};
}


export default entitiesList

export const getAllEntities = state => state.entitiesList.allEntities;
export const getEntities = state => state.entitiesList.entities;
export const getSingleRequested = state => state.entitiesList.singleRequested;
export const getEntity = (state, id) => state.entitiesList.allEntities.find(t=>t._id === id);
export const getEntitiesPending = state => state.entitiesList.pending;
export const getEntitiesError = state => state.entitiesList.error;


