import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    meals: [],
    user: null,
    error: null
}
const strategies = {
    FETCH_MEALS_ERROR: fetchMealsErrorTransformer,
    FETCH_MEALS_PENDING: fetchMealsPendingTransformer,
    FETCH_MEALS_SUCCESS: fetchMealsSuccessTransformer,
    ADD_MEAL_SUCCESS: addMealSuccessTransformer,
    UPDATE_MEAL_SUCCESS: updateMealSuccessTransformer,
    REMOVE_MEAL_SUCCESS: removeMealSuccessTransformer,
};
const mealsList = createReducer(initialState, strategies);

function fetchMealsErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function fetchMealsPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchMealsSuccessTransformer(state, action) {
    return { ...state, pending: false, meals: action.meals.data, user: action.id };
}
function addMealSuccessTransformer (state, action){
    let currentMeals = state.meals;
    if(action.meal.user === state.user) currentMeals.push(action.meal);

    return { ...state, pending: false, meals: currentMeals };
}
function updateMealSuccessTransformer (state, action){
    let currentMeals = state.meals.filter(wi=>wi._id.toString() !== action.id.toString());
    if(action.meal.user === state.user) currentMeals.push({
        ...action.meal,
        saved: true,
        _id: action.id
    });
    return { ...state, pending: false, meals: currentMeals };
}
function removeMealSuccessTransformer (state, action){
    let currentMeals = state.meals.filter(wi=>wi._id.toString() !== action.id.toString());
    return { ...state, pending: false, meals: currentMeals };
}

export default mealsList

export const getMeals = state => state.mealsList.meals;
export const getMealsUser = state => state.mealsList.user;
export const getMealsPending = state => state.mealsList.pending;
export const getMealsError = state => state.mealsList.error;

