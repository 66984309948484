import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    admins: null,
    entityId: null,
    adminsFromQuery: [],
    error: null,
    all: false
}
const strategies = {
    FETCH_ADMINS_ERROR: fetchAdminsErrorTransformer,
    FETCH_ADMINS_PENDING: fetchAdminsPendingTransformer,
    FETCH_ADMINS_SUCCESS: fetchAdminsSuccessTransformer,
    FETCH_ALL_ADMINS_SUCCESS: fetchAllAdminsSuccessTransformer,
    FETCH_ADD_ADMIN_SUCCESS: fecthAddAdminSuccessTransformer,
    FETCH_ADMIN_SUCCESS: fecthAdminSuccessTransformer,
    UPDATE_ADMIN_SUCCESS: updateAdminSuccessTransfromer,
    DELETE_ADMIN_SUCCESS: deleteAdminSuccessTransfromer,
    NEW_ADMIN_SUCCESS : newAdminSuccessTransfromer,
    FETCH_ADMINS_FROM_QUERY_SUCCESS: fetchAdminsFromQuerySuccessTransformer,
    SET_ALL_ADMINS_FETCHED: setAllAdminsFetchedTransformer
};
const adminsList = createReducer(initialState, strategies);

function fetchAdminsErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function fetchAdminsPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchAdminsSuccessTransformer(state, action) {
    return { ...state, pending: false, admins: action.admins.data, adminsFromQuery: [], entityId: action.entityId  };
}
function fetchAllAdminsSuccessTransformer(state, action) {
    return { ...state, pending: false, admins: action.admins.data, adminsFromQuery: [], entityId: null };
}
function fecthAddAdminSuccessTransformer(state, action){
    let currentAdmins = state.admins;
    currentAdmins.push(action.admin.data);
    return { ...state, pending: false, admins: currentAdmins, adminsFromQuery: [] };
}
function fecthAdminSuccessTransformer(state, action){
    let adminString = state.admins.filter(t=>t._id !== action.id);
    let adminToUpdate = state.admins.find(t=>t._id === action.id);
    const newAdmin = {
        ...adminToUpdate,
        active: action.active
    }
    adminString.push(newAdmin);

    return { ...state, pending: false, admins: adminString, adminsFromQuery: [] };
}
function updateAdminSuccessTransfromer(state, action){
    let adminString = state.admins.filter(t=>t._id !== action.admin.data._id);
    adminString.push(action.admin.data);
    return { ...state, pending: false, admins: adminString, adminsFromQuery: [] };
}
function newAdminSuccessTransfromer(state, action){
    let adminString = state.admins;
    adminString.push(action.admin.data);
    return { ...state, pending: false, admins: adminString, adminsFromQuery: [] };
}
function deleteAdminSuccessTransfromer(state, action){
    let adminString = state.admins.filter(t=>t._id !== action.id);
    return { ...state, pending: false, admins: adminString, adminsFromQuery: [] };
}

function fetchAdminsFromQuerySuccessTransformer(state, action) {
    let adminString = action.admins;
    return { ...state, pending: false, adminsFromQuery: adminString };
}

function setAllAdminsFetchedTransformer(state,action){
    return { ...state, all: true };
}

export default adminsList

export const getAllAdminsFetched = state => state.adminsList.all;
export const getAdminsFromQuery = state => state.adminsList.adminsFromQuery;
export const getAdminsEntityId = state => state.adminsList.entityId;
export const getAdmin = (state, id) => state.adminsList.admins === null ? null : state.adminsList.admins.find(t=>t._id === id);
export const getAdmins = state => state.adminsList.admins;
export const getAdminsPending = state => state.adminsList.pending;
export const getAdminsError = state => state.adminsList.error;
