import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    users: [],
    usersFromQuery: [],
    entity: null,
    program: null,
    error: null,
    all: false
}
const strategies = {
    FETCH_USERS_ERROR: fetchUsersErrorTransformer,
    FETCH_USERS_PENDING: fetchUsersPendingTransformer,
    FETCH_USERS_REMOVE_PENDING: fetchUsersRemovePendingTransformer,
    FETCH_USERS_SUCCESS: fetchUsersSuccessTransformer,
    FETCH_PROGRAM_USERS_SUCCESS: fetchProgramUsersSuccessTransformer,
    FETCH_USER_SUCCESS: fetchUserSuccessTransformer,
    ADD_USER_SUCCESS: addUserSuccessTransformer,
    REMOVE_USER_SUCCESS: removeUserSuccessTransformer,
    UPDATE_USER_SUCCESS: updateUserSuccessTransformer,
    UPDATE_PROGRAM_USER_SUCCESS: updateProgramUserSuccessTransformer,
    DELETE_USER_SUCCESS: deleteUserSuccessTransformer,
    FETCH_USERS_FROM_QUERY_SUCCESS: fetchUsersFromQuerySuccessTransformer,
    FETCH_ADD_USERS_SUCCESS: fetchAddUsersSuccessTransformer,
    FETCH_ADD_USER_SUCCESS: fetchAddUserSuccessTransformer,
    SET_ALL_USERS_FETCHED: setAllUsersFetchedTransformer
};
const usersList = createReducer(initialState, strategies);

function fetchUsersErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function fetchUsersPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchUsersRemovePendingTransformer(state) {
    return { ...state, pending: false };
}
function fetchUsersSuccessTransformer(state, action) {
    let newUsers = [];
    if(state.entity === action.entity && state.program === action.program){
      const existingUsers = state.users;
      const existingUsersIds = existingUsers.map(existingUser => existingUser._id);
      newUsers = action.users.data.filter(user=>!existingUsersIds.includes(user._id));
      newUsers = newUsers.concat(existingUsers);
    }else{
      newUsers = action.users.data;
      if(action.entity === 'all'){
          const existingUsers = state.users.filter(user=>user.fetched===true);
          const existingUsersIds = existingUsers.map(existingUser => existingUser._id);
          newUsers = action.users.data.filter(user=>!existingUsersIds.includes(user._id));
          newUsers = newUsers.concat(existingUsers);
      }
    }
    return { ...state, pending: false, users: newUsers, entity: action.id, program: null };
}
function fetchProgramUsersSuccessTransformer(state, action) {
    return { ...state, pending: false, users: action.users.data, program: action.id, entity: null };
}

function fetchUserSuccessTransformer(state, action) {
    let objInd = 0;
    let userString = state.users.filter((t,index)=>{
        if(t._id === action.id){
            objInd = index;
            return false;
        }
        return true;
    });
    const newUser = {
        ...action.user,
        _id: action.id,
        userentitiesinfo: action.user.entitiesinfo,
        fetched: true
    }
    userString.splice(objInd, 0, newUser);
    return { ...state, pending: false, users: userString, usersFromQuery: []};
}

function addUserSuccessTransformer(state, action) {
    let userString = state.users;
    userString.push(action.user);
    return { ...state, pending: false, users: userString, usersFromQuery: [] };
}

function fetchAddUserSuccessTransformer(state, action) {
  const debug = false;
  if(debug) console.log(`### fetchAddUserSuccessTransformer`)
  let userString = state.users.filter(u=>u.user._id!==action.user._id);
  if(debug) console.log(`### state.users=${JSON.stringify(state.users,null,2)}`)
  if(debug) console.log(`### action.user=${JSON.stringify(action.user,null,2)}`)

  const userToAdd = {
    user: action.user,
    startDate: action.startDate
  };
  userString = userString.concat(userToAdd);
  if(debug) console.log(`### userString=${JSON.stringify(userString,null,2)}`)
  return { ...state, pending: false, users: userString, usersFromQuery: [] };
}

function fetchAddUsersSuccessTransformer(state, action) {
  const debug = false;
  if(debug) console.log(`### fetchAddUsersSuccessTransformer`)
  let userString = state.users;
  if(debug) console.log(`### state.users=${JSON.stringify(state.users,null,2)}`)
  if(debug) console.log(`### action.users=${JSON.stringify(action.users,null,2)}`)

  const usersToAddd = action.users.map(u=>({
    user: u,
    startDate: action.startDate
  }))
  userString = userString.concat(usersToAddd);
  userString = [...new Set(userString)]; 
  if(debug) console.log(`### userString=${JSON.stringify(userString,null,2)}`)
  return { ...state, pending: false, users: userString, usersFromQuery: [] };
}
function updateUserSuccessTransformer(state, action) { 
    let userString = state.users.filter(t=>t._id !== action.id);
    let existinguser = state.users.find(t=>t._id === action.id);
    if(existinguser!==null){
        const newUser = {
            ...existinguser,
            ...action.user,
            _id: action.id
        }
        userString.push(newUser);
    }
    return { ...state, pending: false, users: userString };
}
function updateProgramUserSuccessTransformer(state, action) { 
  const debug = false;
  if(debug) console.log(`### updateProgramUserSuccessTransformer`)
  if(debug) console.log(`### action.user=${JSON.stringify(action.user,null,2)}`)
  if(debug) console.log(`### action.program=${JSON.stringify(action.program,null,2)}`)

  if(debug) console.log(`### state.users=${JSON.stringify(state.users,null,2)}`)

  let userString = state.users.filter(t=>(t.user._id !== action.user));
  if(debug) console.log(`### userString=${JSON.stringify(userString,null,2)}`)
  
  return { ...state, pending: false, users: userString, program:action.program._id };
}
function deleteUserSuccessTransformer(state, action) { 
    const currentUsers = state.users;
    const newUsers = currentUsers.filter(t=>t._id !== action.id);
    return { ...state, pending: false, users: newUsers};
}

function fetchUsersFromQuerySuccessTransformer(state, action) {
    let userString = action.users;
    return { ...state, pending: false, usersFromQuery: userString };
}

function removeUserSuccessTransformer(state, action) { 
    let userString = state.users.filter(t=>t._id !== action.id);
    return { ...state, pending: false, users: userString };
}

function setAllUsersFetchedTransformer(state,action){
    return { ...state, all: true };
}

export default usersList

export const getUsers = state => state.usersList.users;
export const getAllUsersFetched = state => state.usersList.all;
export const getUsersFromQuery = state => state.usersList.usersFromQuery;
export const getUsersEntity = state => state.usersList.entity;
export const getUsersNotInEntity = (state, id) => state.usersList.users.find(t=> !t.entities.includes(id));
export const getUser = (state, id) => state.usersList.users.find(t=>t._id === id);
export const getUsersPending = state => state.usersList.pending;
export const getUsersError = state => state.usersList.error;
export const getProgram = state => state.usersList.program;
