import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    participations: [],
    error: null
}
const strategies = {
    FETCH_PARTICIPATIONS_ERROR: fetchParticipationsErrorTransformer,
    FETCH_PARTICIPATIONS_PENDING: fetchParticipationsPendingTransformer,
    FETCH_PARTICIPATIONS_SUCCESS: fetchParticipationsSuccessTransformer,
    UPDATE_PARTICIPATION_SUCCESS: updateParticipationSuccessTransformer
};
const participationsList = createReducer(initialState, strategies);

function fetchParticipationsErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function fetchParticipationsPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchParticipationsSuccessTransformer(state, action) {
    return { ...state, pending: false, participations: action.participations.data};
}
function updateParticipationSuccessTransformer(state,action) {
    let allParticipations = state.participations.filter(t=>t._id !== action.participation.data._id);
    allParticipations.push(action.participation.data);
    return { ...state, pending: false, participations: allParticipations };
}
export default participationsList

export const getParticipations = state => state.participationsList.participations;
export const getParticipationsPending = state => state.participationsList.pending;
export const getParticipationsError = state => state.participationsList.error;

