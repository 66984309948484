import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    subscriptions: [],
    error: null
}
const strategies = {
    FETCH_SUBSCRIPTIONS_PENDING: fetchSubscriptionsPendingTransformer,
    FETCH_SUBSCRIPTIONS_SUCCESS: fetchSubscriptionsSuccessTransformer,
    FETCH_SUBSCRIPTIONS_ERROR: fetchSubscriptionsErrorTransformer,
};
const subscriptionList = createReducer(initialState, strategies);

function fetchSubscriptionsPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchSubscriptionsSuccessTransformer(state, action) {
    return { ...state, pending: false, subscriptions: action.subscriptions };
}
function fetchSubscriptionsErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}

export default subscriptionList

export const getSubscriptions = state => state.subscriptionList.subscriptions;
export const getSubscriptionsPending = state => state.subscriptionList.pending;
export const getSubscriptionsError = state => state.subscriptionList.error;