import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    exercises: [],
    entity: null,
    error: null,
    last: null
}
const strategies = {
    FETCH_EXERCISES_ERROR: fetchExercisesErrorTransformer,
    FETCH_EXERCISES_PENDING: fetchExercisesPendingTransformer,
    FETCH_EXERCISES_REMOVE_PENDING: fetchExercisesRemovePendingTransformer,
    FETCH_EXERCISES_SUCCESS: fetchExercisesSuccessTransformer,
    FETCH_EXERCISE_SUCCESS: fetchExerciseSuccessTransformer,
    UPDATE_EXERCISE_SUCCESS: updateExerciseSuccessTransformer,
    UPDATE_EXERCISE_OWNER_SUCCESS: updateExerciseOwnerSuccessTransformer,
    ADD_NEW_EXERCISE_SUCCESS: addNewExerciseSuccessTransformer,
    REMOVE_EXERCISE_SUCCESS: removeExerciseSuccessTransformer,
    REMOVE_LAST_FLAGGED_EXERCISE: removeLastFlaggedExerciseTransformer
};
const exercisesList = createReducer(initialState, strategies);

function fetchExercisesErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function fetchExercisesPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchExercisesRemovePendingTransformer(state) {
    return { ...state, pending: false };
}
function fetchExercisesSuccessTransformer(state, action) {
    return { ...state, pending: false, exercises: action.exercises, entity: action.id };
}
function fetchExerciseSuccessTransformer(state, action) {
    let exerciseString = state.exercises.filter(t=>t._id !== action.id);
    const newExercise = {
        ...action.exercise,
        _id: action.id,
        fetched: true
    }
    exerciseString.push(newExercise);
    return { ...state, pending: false, exercises: exerciseString };
}
function updateExerciseSuccessTransformer(state, action) { 
    let exerciseString = state.exercises.filter(t=>t._id !== action.id);
    const newExercise = {
        ...action.exercise,
        _id: action.id
    }
    exerciseString.push(newExercise);
    return { ...state, pending: false, exercises: exerciseString};
}
function updateExerciseOwnerSuccessTransformer(state, action) { 
    let exerciseString = state.exercises.filter(t=>t._id !== action.id);
    const newExercise = {
        ...action.exercise,
        _id: action.id,
        entity: action.owner
    }
    exerciseString.push(newExercise);
    return { ...state, pending: false, exercises: exerciseString};
}

function addNewExerciseSuccessTransformer(state, action) {
    let exerciseString = state.exercises;
    exerciseString.push(action.exercise);
    return { ...state, pending: false, exercises: exerciseString, last: action.exercise };
}

function removeExerciseSuccessTransformer(state, action) {
    let exerciseString = state.exercises.filter(t=>t._id !== action.id);
    return { ...state, pending: false, exercises: exerciseString };
}

function removeLastFlaggedExerciseTransformer(state, action) {
    return { ...state, last: null };
}

export default exercisesList

export const getLastExercise = state => state.exercisesList.last;
export const getExercises = state => state.exercisesList.exercises;
export const getExercisesEntity = state => state.exercisesList.entity;
export const getExercise = (state, id) => state.exercisesList.exercises.find(t=>t._id === id);
export const getExercisesPending = state => state.exercisesList.pending;
export const getExercisesError = state => state.exercisesList.error;
