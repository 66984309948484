import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    results: [],
    error: null
}
const strategies = {
    FETCH_RESULTS_ERROR: fetchResultsErrorTransformer,
    FETCH_RESULTS_PENDING: fetchResultsPendingTransformer,
    FETCH_RESULTS_SUCCESS: fetchResultsSuccessTransformer,
    ADD_RESULT_SUCCESS: addResultSuccessTransformer,
    UPDATE_RESULT_SUCCESS: updateResultSuccessTransformer,
    REMOVE_RESULT_SUCCESS: removeResultSuccessTransformer
};
const resultsList = createReducer(initialState, strategies);

function fetchResultsErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function fetchResultsPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchResultsSuccessTransformer(state, action) {
    return { ...state, pending: false, results: action.results.data};
}
function addResultSuccessTransformer (state, action){
    let currentResults = state.results;
    currentResults.unshift(action.result);
    return { ...state, pending: false, results: currentResults };
}
function updateResultSuccessTransformer (state, action){
    let currentResults = state.results.filter(t=>t._id.toString() !== action.id.toString());
    currentResults.unshift(action.result);

    return { ...state, pending: false, results: currentResults };
}
function removeResultSuccessTransformer (state, action){
    let currentResults = state.results.filter(t=>t._id.toString() !== action.id.toString());
    return { ...state, pending: false, results: currentResults };
}
export default resultsList

export const getResults = (state, programId) => state.resultsList.results.filter(r=>r.program===programId);
export const getResultsPending = state => state.resultsList.pending;
export const getResultsError = state => state.resultsList.error;

