import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    workouts: [],
    entity: null,
    error: null,
    all: false,
    last: null
}
const strategies = {
    FETCH_WORKOUTS_ERROR: fetchWorkoutsErrorTransformer,
    FETCH_WORKOUTS_PENDING: fetchWorkoutsPendingTransformer,
    FETCH_WORKOUTS_SUCCESS: fetchWorkoutsSuccessTransformer,
    FETCH_WORKOUT_SUCCESS: fetchWorkoutSuccessTransformer,
    UPDATE_WORKOUT_SUCCESS: updateWorkoutSuccessTransformer,
    ADD_NEW_WORKOUT_SUCCESS: addNewWorkoutSuccessTransformer,
    REMOVE_WORKOUT_SUCCESS: removeWorkoutSuccessTransformer,
    REMOVE_WORKOUT_PENDING: removeWorkoutPendingTransformer,
    REMOVE_LAST_WORKOUT: removeLastWorkoutTransformer
};
const workoutsList = createReducer(initialState, strategies);

function fetchWorkoutsErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function fetchWorkoutsPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchWorkoutsSuccessTransformer(state, action) {
  const debug = false;
  let stateWorkouts = action.id === state.entity ? state.workouts : [];
  if(debug) console.log(`### action.id=${action.id}, state.entity =${state.entity }`)
  if(debug) console.log(`### stateWorkouts=${JSON.stringify(stateWorkouts,null,2)}`)
  let fetchedWorkouts = action.workouts.data;
  if(debug) console.log(`### fetchedWorkouts=${JSON.stringify(fetchedWorkouts,null,2)}`)

  let fetchedWorkoutsToKeep = [];
  fetchedWorkouts.map(fetchedWorkout=>{
    if(fetchedWorkout.workoutData || !stateWorkouts.find(w=>w._id===fetchedWorkout._id)) fetchedWorkoutsToKeep.push(fetchedWorkout);
  });

  if(debug) console.log(`### fetchedWorkoutsToKeep=${JSON.stringify(fetchedWorkoutsToKeep,null,2)}`)
  const fetchedWorkoutsToKeepIds = fetchedWorkoutsToKeep.map(fetchedWorkoutToKeep=>fetchedWorkoutToKeep._id);
  if(debug) console.log(`### fetchedWorkoutsToKeepIds=${fetchedWorkoutsToKeepIds}`)
  stateWorkouts = stateWorkouts.filter(stateWorkout=>!fetchedWorkoutsToKeepIds.includes(stateWorkout._id));
  if(debug) console.log(`### 2 stateWorkouts=${JSON.stringify(stateWorkouts,null,2)}`)

  stateWorkouts = stateWorkouts.concat(fetchedWorkoutsToKeep);
  if(debug) console.log(`### 3 stateWorkouts=${JSON.stringify(stateWorkouts,null,2)}`)
  return { ...state, pending: false, workouts: stateWorkouts, entity: action.id, all: action.all };
}
function fetchWorkoutSuccessTransformer(state, action) {
  const debug = false;
  if(debug) console.log(`### action.id=${action.id}`)
  if(debug) console.log(`### action.workout=${JSON.stringify(action.workout)}`)
  if(debug) console.log(`### state.workouts=${JSON.stringify(state.workouts)}`)
  if(debug) console.log(`### state.workouts.length=${state.workouts.length}`)

  let workoutString = state.workouts.filter(t=>{return t._id !== action.id || (action.workout.data.status === "cloning" && t.status !== "cloning")});
  if(debug) console.log(`### workoutString.length=${workoutString.length}`)
  
  workoutString.push(action.workout.data);
  if(action.setLast) return { ...state, pending: false, workouts: workoutString, entity: action.workout.data.entities[0], last: action.workout.data};
  else return { ...state, pending: false, workouts: workoutString, entity: action.workout.data.entities[0], last: null };
}
function updateWorkoutSuccessTransformer(state, action) { 
    let workoutString = state.workouts.filter(t=>t._id !== action.id);
    const newWorkout = {
        ...action.workout,
        _id: action.id
    }
    workoutString.push(newWorkout);
    return { ...state, pending: false, workouts: workoutString};
}
function addNewWorkoutSuccessTransformer(state, action) {
    let workoutString = state.workouts;
    workoutString.push(action.workout);

    if(action.setLast) return { ...state, pending: false, workouts: workoutString, last: action.workout  };
    else return { ...state, pending: false, workouts: workoutString, last: null  };
}

function removeWorkoutSuccessTransformer(state, action) {
    let workoutString = state.workouts.filter(t=>t._id !== action.id);
    return { ...state, pending: false, workouts: workoutString };
}

function removeLastWorkoutTransformer(state,action){
  return { ...state, pending: false, last: null };
}

function removeWorkoutPendingTransformer(state,action){
  return { ...state, pending: false };
}

export default workoutsList

export const getWorkouts = state => state.workoutsList.workouts;
export const getAllWorkoutsFetched = state => state.workoutsList.all;
export const getWorkout = (state, id) => state.workoutsList.workouts.find(t=>t._id === id);
export const getLastWorkout = state => state.workoutsList.last;
export const getWorkoutsEntity = state => state.workoutsList.entity;
export const getWorkoutsPending = state => state.workoutsList.pending;
export const getWorkoutsError = state => state.workoutsList.error;




