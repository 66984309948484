import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    products: [],
    entityId: null,
    error: null
}
const strategies = {
    FETCH_PRODUCTS_ERROR: fetchProductsErrorTransformer,
    FETCH_PRODUCTS_PENDING: fetchProductsPendingTransformer,
    FETCH_PRODUCTS_SUCCESS: fetchProductsSuccessTransformer,
    SAVE_PRODUCT_SUCCESS: saveProductSuccessTransformer,
    UPDATE_PRODUCT_SUCCESS: updateProductSuccessTransformer,
    DELETE_PRODUCT_SUCCESS: deleteProductSuccessTransformer
};
const productsList = createReducer(initialState, strategies);

function fetchProductsErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function fetchProductsPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchProductsSuccessTransformer(state, action) {
    return { ...state, pending: false, products: action.products.data, entityId: action.entityId  };
}

function saveProductSuccessTransformer(state,action) {
    let allProducts = state.products;
    allProducts.push(action.product.data);
    return { ...state, pending: false, products: allProducts };
}

function updateProductSuccessTransformer(state,action) {
    let allProducts = state.products.filter(t=>t._id !== action.product.data._id);
    allProducts.push(action.product.data);
    return { ...state, pending: false, products: allProducts };
}

function deleteProductSuccessTransformer(state,action) {
    let allProducts = state.products.filter(t=>t._id !== action.product.data._id);
    return { ...state, pending: false, products: allProducts };
}

export default productsList

export const getProducts = state => state.productsList.products;
export const getProductsEntityId = state => state.productsList.entityId;
export const getProductsPending = state => state.productsList.pending;
export const getProductsError = state => state.productsList.error;


