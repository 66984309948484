import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    orders: [],
    entityId: null,
    error: null
}
const strategies = {
    FETCH_ORDERS_PENDING: fetchOrdersPendingTransformer,
    FETCH_ORDERS_SUCCESS: fetchOrdersSuccessTransformer,
    FETCH_ORDERS_ERROR: fetchOrdersErrorTransformer,
    DELETE_ORDER_SUCCESS: deleteOrderSuccessTransformer,
    UPDATE_ORDER_SUCCESS: updateOrderSuccessTransformer,
    CREATE_ORDER_SUCCESS: createOrderSuccessTransformer,
    REMOVE_LAST_ORDER: removeLastOrderTransformer,
    FETCH_PARTICIPATION_ORDER_SUCCESS: fetchParticipationOrderSuccessTransformer
};
const orderList = createReducer(initialState, strategies);

function fetchOrdersPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchOrdersSuccessTransformer(state, action) {
    return { ...state, pending: false, orders: action.orders, entityId: action.entityId };
}
function fetchParticipationOrderSuccessTransformer(state, action) {
    return { ...state, pending: false, orders: [action.order], entityId: null };
}

function fetchOrdersErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function deleteOrderSuccessTransformer(state, action) {
    const newOrders = state.orders.filter(order=>order._id !== action.order._id);
    return { ...state, pending: false, orders: newOrders};
}
function updateOrderSuccessTransformer(state, action) {
    let newOrders = state.orders.filter(order=>order._id !== action.order._id);
    newOrders.push(action.order);
    return { ...state, pending: false, orders: newOrders};
}
function createOrderSuccessTransformer(state, action) {
    let newOrders = state.orders;
    const lastOrder = {
        ...action.order,
        lastcreated: true
    }
    newOrders.push(lastOrder);
    return { ...state, pending: false, orders: newOrders};
}
function removeLastOrderTransformer(state, action) {
    let lastOrders = state.orders.filter(order=>(order.lastcreated && order.lastcreated === true));
    let newOrders = state.orders.filter(order=>(!order.lastcreated || order.lastcreated === false));
    let updatedLastOrders = [];
    lastOrders.map(order=>{
        delete order.lastcreated;
        updatedLastOrders.push(order);
    })
    newOrders = newOrders.concat(updatedLastOrders);
    return { ...state, pending: false, orders: newOrders};
}

export default orderList

export const getOrders = state => state.orderList.orders;
export const getOrdersEntityId = state => state.orderList.entityId;
export const getLastOrder = state => state.orderList.orders.find(order=>(order.lastcreated && order.lastcreated === true));
export const getOrdersPending = state => state.orderList.pending;
export const getOrdersError = state => state.orderList.error;