import { combineReducers } from 'redux'
import darkmode from './darkmode'
import entitiesList from './entities'
import adminsList from './admins'
import usersList from './users'
import exercisesList from './exercises'
import programsList from './programs'
import workoutsList from './workouts'
import orderList from './orders'
import chargeList from './charges'
import sessionList from './session'
import productsList from './products'
import testsList from './tests'
import weightinsList from './weightins'
import mealsList from './meals'
import mealplansList from './mealplans'
import resultsList from './results'
import participationsList from './participations'
import ingredientsList from './ingredients'
import subscriptionList from './subscriptions'

import { localizeReducer } from "react-localize-redux";

const appReducer = combineReducers({
  localize: localizeReducer,
  entitiesList,
  adminsList,
  usersList,
  exercisesList,
  programsList,
  workoutsList,
  weightinsList,
  mealsList,
  mealplansList,
  ingredientsList,
  testsList,
  sessionList,
  productsList,
  orderList,
  chargeList,
  resultsList,
  participationsList,
  subscriptionList,
  darkmode
});

const rootReducer = (state, action) => {   
  if(action.type === 'DESTROY_SESSION')
    state = {
      localize: state.localize
    };
  
  return appReducer(state, action);
};

export default rootReducer;