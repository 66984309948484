import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    charges: [],
    error: null
}
const strategies = {
    FETCH_CHARGES_PENDING: fetchChargesPendingTransformer,
    FETCH_CHARGES_SUCCESS: fetchChargesSuccessTransformer,
    FETCH_CHARGES_ERROR: fetchChargesErrorTransformer,
    DELETE_CHARGE_SUCCESS: deleteChargeSuccessTransformer,
    UPDATE_CHARGE_SUCCESS: updateChargeSuccessTransformer,
    CREATE_CHARGE_SUCCESS: createChargeSuccessTransformer,
    FETCH_PARTICIPATION_CHARGE_SUCCESS: fetchParticipationChargeSuccessTransformer
};
const chargeList = createReducer(initialState, strategies);

function fetchChargesPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchChargesSuccessTransformer(state, action) {
    return { ...state, pending: false, charges: action.charges };
}
function fetchParticipationChargeSuccessTransformer(state, action) {
    return { ...state, pending: false, charges: [action.charge], entityId: null };
}
function fetchChargesErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function deleteChargeSuccessTransformer(state, action) {
    const newCharges = state.charges.filter(charge=>charge._id !== action.charge._id);
    return { ...state, pending: false, charges: newCharges};
}
function updateChargeSuccessTransformer(state, action) {
    let newCharges= state.charges.filter(charge=>charge._id !== action.charge._id);
    newCharges.push(action.charge);
    return { ...state, pending: false, charges: newCharges};
}
function createChargeSuccessTransformer(state, action) {
    let newCharges= state.charges;
    newCharges.push(action.charge);
    return { ...state, pending: false, charges: newCharges};
}

export default chargeList

export const getCharges = state => state.chargeList.charges;
export const getChargesPending = state => state.chargeList.pending;
export const getChargesError = state => state.chargeList.error;