import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    programs: [],
    entity: null,
    error: null
}
const strategies = {
    FETCH_PROGRAMS_ERROR: fetchProgramsErrorTransformer,
    FETCH_PROGRAMS_PENDING: fetchProgramsPendingTransformer,
    FETCH_PROGRAMS_SUCCESS: fetchProgramsSuccessTransformer,
    FETCH_PROGRAM_SUCCESS: fetchProgramSuccessTransformer,
    UPDATE_PROGRAM_SUCCESS: updateProgramSuccessTransformer,
    ADD_NEW_PROGRAM_SUCCESS: addNewProgramSuccessTransformer,
    REMOVE_PROGRAM_SUCCESS: removeProgramSuccessTransformer,
    REMOVE_LAST_FLAGGED_PROGRAM : removeLastFlaggedProgramTransformer
};
const programsList = createReducer(initialState, strategies);

function fetchProgramsErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function fetchProgramsPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchProgramsSuccessTransformer(state, action) {
    return { ...state, pending: false, programs: action.programs.data, entity: action.id };
}
function fetchProgramSuccessTransformer(state, action) {
    let programString = state.programs.filter(t=>t._id !== action.id);
    programString.push(action.program.data);
    return { ...state, pending: false, programs: programString };
}
function updateProgramSuccessTransformer(state, action) { 
    let programString = state.programs.filter(t=>t._id !== action.id);
    const newProgram = {
        ...action.program,
        _id: action.id
    }
    programString.push(newProgram);
    return { ...state, pending: false, programs: programString};
}
function addNewProgramSuccessTransformer(state, action) {
    let programString = state.programs;
    programString.push(action.program);
    return { ...state, pending: false, programs: programString, last: action.program  };
}

function removeProgramSuccessTransformer(state, action) {
    let programString = state.programs.filter(t=>t._id !== action.id);
    return { ...state, pending: false, programs: programString };
}
function removeLastFlaggedProgramTransformer(state, action) {
    return { ...state, last: null };
}

export default programsList

export const getLastProgram = state => state.programsList.last;
export const getPrograms = state => state.programsList.programs;
export const getProgram = (state, id) => state.programsList.programs.find(t=>t._id === id);
export const getProgramsEntity = state => state.programsList.entity;
export const getProgramsPending = state => state.programsList.pending;
export const getProgramsError = state => state.programsList.error;

